import React from "react"
import SEO from "../components/seo"

export default function AvisoDePrivacidad() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <SEO
        title="Aviso de Privacidad"
        description="Conoce nuestro aviso de privacidad"
        url="/privacidad"
      />
      <div
        style={{
          margin: "105px auto auto",
          maxWidth: "900px",
          padding: "0 0.7em",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <h1>Aviso de Privacidad Integral Tornebo Clientes</h1>
        <p>
          En Tornebo, una de nuestras principales preocupaciones es la seguridad
          de la información de los usuarios y de sus familias, por lo que, en
          aras de dar una mayor protección y en cumplimiento con lo previsto en
          la Ley Federal de Datos Personales en Posesión de los Particulares (en
          lo sucesivo la “Ley”) y su Reglamento (en lo sucesivo el
          “Reglamento”), se expide el presente Aviso de Privacidad en los
          siguientes términos y condiciones:
        </p>
        <h2>1. Responsable de los Datos Personales.</h2>
        <p>
          En términos del presente Aviso de Privacidad, Tornebo, S.A.P.I. de
          C.V. (en lo sucesivo “Tornebo”) será la persona que decida sobre el
          tratamiento de los datos personales, señala como domicilio el ubicado
          en Avenida Presidente Masaryk número 111, PH-1, Colonia Chapultepec
          Los Morales, 11570, Miguel Hidalgo, Ciudad de México., número
          telefónico (55) 5027 3000 y el sitio web https://uniomx.com/.
        </p>
        <h2>2. Introducción al Aviso de Privacidad.</h2>
        <p>
          2.1. Confidencialidad. Tornebo mantendrá absoluta reserva y estricta
          confidencialidad, respecto de todos los datos personales e información
          que, con motivo de la prestación de nuestros servicios nos proporcione
          el Cliente, respecto de los cuales Tornebo reciba datos personales y
          serán protegidos con las medidas de seguridad administrativas, físicas
          y técnicas, establecidas en el Reglamento. La transferencia de datos
          únicamente se llevará a cabo previo consentimiento del titular.
        </p>
        <p>
          2.2. Titulares. El presente Aviso de Privacidad está dirigido de forma
          específica a aquellas personas a las que Tornebo les preste sus
          servicios y a los usuarios de las plataformas digitales de Tornebo.
        </p>
        <p>
          2.3. Datos Personales de Menores de Edad y Personas en Estado de
          Interdicción. Con motivo de la prestación de sus servicios, Tornebo
          será responsable del tratamiento de datos personales y datos
          personales sensibles de menores de edad y personas en estado de
          interdicción. Los datos que Tornebo reciba de los menores de edad y
          personas en estado de interdicción, serán directamente proporcionados
          por aquellas personas que tengan la representación legal. En adición a
          lo anterior, y previo a la recepción de los datos señalados en el
          presente apartado, Tornebo recabará el consentimiento de sus
          representantes legales, según sea el caso, por escrito o por medios
          electrónicos.
        </p>
        <p>
          2.4. Consentimiento. Al proporcionar sus Datos Personales a Tornebo
          por cualquier medio, autoriza a que sean tratados y transferidos en
          términos del presente Aviso de Privacidad. No obstante lo anterior,
          Tornebo buscará recabar las constancias por escrito o por medios
          electrónicos, en las que señalen su consentimiento expreso, tratándose
          de Datos Personales Sensibles y Datos Patrimoniales o Financieros
          (según dichos términos se describen más adelante).
        </p>
        <p>
          2.5. Listado de Exclusión. De conformidad con la fracción IV del
          artículo 2 del Reglamento, se informa que Tornebo cuenta con una base
          de datos cuyo objeto es, registrar de manera gratuita la negativa que
          realicen los titulares respecto del tratamiento de sus datos
          personales.
        </p>
        <h2>Definiciones</h2>
        <h3>
          Para mayor facilidad de lectura y para efectos del presente Aviso de
          Privacidad, y aquellas modalidades de avisos de privacidad a que hace
          referencia el artículo Decimoctavo de los Lineamientos, cuando así sea
          procedente, las siguientes palabras tendrán las definiciones que a
          continuación se enlistan:
        </h3>
        <ol>
          <li>
            Aviso de Privacidad. El presente aviso de privacidad de Tornebo,
            emitido en cumplimiento de la Ley.
          </li>
          <li>
            Cliente. Aquella persona a quien Tornebo le presta sus servicios, y
            respecto de quien tendrá y tratará datos personales y Datos
            Personales Sensibles en términos del presente Aviso de Privacidad.
          </li>
          <li>
            Derechos ARCO. El derecho que tienen los titulares de Acceso,
            Rectificación, Cancelación y Oposición, en términos del Aviso de
            Privacidad y la legislación aplicable.
          </li>
          <li>
            Finalidades Principales. En términos del artículo segundo del Anexo
            de los Lineamientos, se entenderá por Finalidades Principales del
            tratamiento de los datos personales, aquellas finalidades de
            tratamiento de datos, que den origen a la relación jurídica entre
            los titulares y Tornebo, es decir, aquella información sin la cual,
            Tornebo se vería imposibilitado para la prestación de sus servicios.
          </li>
          <li>
            Finalidades Secundarias. En términos del artículo segundo del Anexo
            de los Lineamientos, se entenderá por Finalidades Secundarias, del
            tratamiento de los datos personales, aquellos datos, que si bien no
            son indispensables para la prestación del servicio, se requieren
            para una mejora en el servicio y Tornebo podrá ofrecer mayores
            servicios al Cliente y de mayor calidad.
          </li>
          <li>
            Ley. La Ley Federal de Datos Personales en Posesión de los
            Particulares.
          </li>
          <li>
            Lineamientos. Los Lineamientos del Aviso de Privacidad, publicados
            en el Diario Oficial de la Federación el día 17 de enero de 2013.
          </li>
          <li>
            Reglamento. El Reglamento de la Ley Federal de Datos Personales en
            Posesión de los Particulares.
          </li>
          <li>
            Tornebo. La sociedad denominada Tornebo, S.A. de C.V., responsable
            del tratamiento de los datos personales en términos del presente
            Aviso de Privacidad y la fracción XIV del artículo 3 de la Ley.
          </li>
        </ol>
        <h3>
          Para la interpretación del presente Aviso de Privacidad, en adición a
          las definiciones antes señaladas y cuando así sea procedente, se
          deberá atender a lo señalado en la Ley, en el Reglamento y en los
          Lineamientos.
        </h3>
        <h2>Categorías de Datos Personales que se tratan.</h2>
        <p>
          En términos del artículo vigésimo segundo de los Lineamentos, para
          efectos del presente Aviso de Privacidad, los datos se catalogarán en
          las siguientes categorías:
        </p>
        <ol>
          <li>
            Datos de Fuentes de Acceso Público. Se refiere a toda aquella
            información que obtenga Tornebo sin transgredir a lo señalado por la
            Ley, el Reglamento y los estándares de protección de datos
            personales nacionales e internacionales, en especial cumplimiento
            con lo señalado en el artículo 7 del Reglamento.
          </li>
          <li>
            Datos de Identificación del Cliente. Se considerará que se recopila,
            bajo esta categoría, la siguiente información del Cliente, para la
            prestación de servicios: Nombre completo, fecha de nacimiento,
            correo electrónico, código postal y género.
          </li>
          <li>
            Datos de Plataformas Digitales. Tornebo cuenta con diversas
            plataformas electrónicas, en las que se incluye página web y
            aplicación móvil y en las que podrá recabar los siguientes datos:
            dirección IP, almacenamiento de preferencias y configuración,
            información de inicio de sesión y autenticación, hora de visita a la
            página web, dispositivo y navegador utilizado, actividad dentro de
            la página web, y cuando el titular así lo señale, correo
            electrónico, nombre y teléfono.
          </li>
          <li>
            Datos Patrimoniales o Financieros. Se considerará que se recopila
            bajo esta categoría, número de cuenta, tipo de cuenta, número de
            tarjeta bancaria, número de cliente y CLABE (Clave Bancaria
            Estandarizada).
          </li>
        </ol>
        <h2>5. Finalidades Primarias del Tratamiento.</h2>
        <p>
          Las Finalidades Primarias, sin las cuales Tornebo no podría prestar
          sus servicios, para las cuales se recaban los datos personales, serán
          las siguientes:
        </p>
        <h4>Datos de Identificación del Cliente.</h4>
        <ol>
          <li>Verificar y confirmar la identidad del Cliente.</li>
          <li>Proporcionar los servicios de Tornebo.</li>
          <li>Atención a requerimientos legales de autoridades competentes.</li>
          <li>
            Realizar las facturas correspondientes por la prestación de
            servicios de Tornebo.
          </li>
          <li>
            Llevar a cabo procesos de cobranza que se pudieran llevar a cabo con
            motivo de la relación jurídica con Tornebo.
          </li>
          <li>
            Cumplir con obligaciones de carácter comercial, fiscal y laboral,
            así como disposiciones jurídicas aplicables.
          </li>
          <li>Atención y servicio al Cliente.</li>
        </ol>
        <h4>Datos de Plataformas Digitales</h4>
        <ol>
          <li>Proporcionar los servicios de Tornebo.</li>
          <li>
            Dar acceso a los servicios brindados a través de las diversas
            plataformas electrónicas, entre las que se incluyen la página web y
            la aplicación móvil.
          </li>
          <li>Atención y servicio al Cliente.</li>
        </ol>
        <h4>Datos Patrimoniales o Financieros.</h4>
        <ol>
          <li>Cobro de los servicios prestados por Tornebo.</li>
        </ol>
        <ol>
          <li>
            Enviar comunicados, vía correo electrónico, de noticias en materia
            de seguridad, únicamente en caso de que el Cliente así lo solicite.
          </li>
          <li>
            Enviar comunicados vía correo electrónico de mejoras, publicidad y
            promociones relativas a los servicios de Tornebo, así como la
            prestación de nuevos servicios, propios, o prestados por terceros
            especializados; únicamente en caso de que el Cliente así lo
            solicite.
          </li>
          <li>
            Realizar encuestas de calidad de los servicios de Tornebo (el
            Cliente no estará obligado a llenar dichas encuestas y todo el
            contenido que libremente señale el Cliente para mejorar los
            servicios, serán en todo momento propiedad de Tornebo, y lo podrá
            explotar de forma irrestricta).
          </li>
          <li>
            La creación de procesos estadísticos para la evaluación, comparación
            y mejora en la calidad y contenido de los servicios de Tornebo.
          </li>
          <li>
            Informar de acciones y convenios que haya realizado para la mejora
            de sus servicios y de la comunidad, en su caso.
          </li>
          <li>
            Específicamente para Datos de Plataformas Digitales: enviar
            publicidad e información en materia de seguridad privada familiar,
            registrar potenciales clientes y mejorar la experiencia del usuario
            que acceda a las plataformas digitales de Tornebo.
          </li>
        </ol>
        <h2>
          Medios y procedimiento para ejercer los derechos de Acceso,
          Rectificación, Cancelación u Oposición “Derechos ARCO”.
        </h2>
        <p>
          7.1. Derechos ARCO. Los titulares gozarán de sus derechos de Acceso,
          Rectificación, Cancelación y Oposición, por sus iniciales “Derechos
          ARCO”, otorgados por la legislación aplicable. Los Derechos ARCO se
          encuentran descritos con mayor detalle en el Reglamento y para pronta
          referencia, se transcriben las descripciones a continuación:
        </p>
        <ul>
          <li style={{ listStyle: "none" }}>
            a) Acceso. El titular, en términos de lo dispuesto por el artículo
            23 de la Ley, tiene derecho a obtener de Tornebo sus datos
            personales, así como información relativa a las condiciones y
            generalidades del tratamiento.
          </li>
          <li style={{ listStyle: "none" }}>
            b) Rectificación. De conformidad con lo dispuesto por el artículo 24
            de la Ley, el titular podrá solicitar en todo momento a Tornebo que
            rectifique sus datos personales que resulten ser inexactos o
            incompletos.
          </li>
          <li style={{ listStyle: "none" }}>
            c) Cancelación. En términos del artículo 25 de la Ley, la
            cancelación implica el cese en el tratamiento por parte de Tornebo,
            a partir de un bloqueo de los mismos y su posterior supresión.
          </li>
          <li style={{ listStyle: "none" }}>
            d) Oposición. En términos del artículo 27 de la Ley, el titular
            podrá, en todo momento, oponerse al tratamiento de sus datos
            personales o exigir que se cese en el mismo para finalidades
            específicas en los casos señalados en el artículo 109 del
            Reglamento.
          </li>
        </ul>
        <p>
          7.2. Procedimiento para Protección de Derechos ARCO. El procedimiento
          para la protección de los Derechos ARCO será gratuito y en los
          términos señalados a continuación:
        </p>
        <p>
          El titular de los datos personales, deberá presentar un escrito libre,
          el cual contenga la información y se adjunten los documentos señalados
          en el artículo 29 de la Ley, que para pronta referencia, se transcribe
          a continuación, en lo conducente:
        </p>
        <ol>
          <li style={{ listStyle: "none" }}>
            a) El nombre del titular y domicilio u otro medio para comunicarle
            la respuesta a su solicitud;
          </li>
          <li style={{ listStyle: "none" }}>
            b) Los documentos que acrediten la identidad o, en su caso, la
            representación legal del titular;
          </li>
          <li style={{ listStyle: "none" }}>
            c) La descripción clara y precisa de los datos personales respecto
            de los que se busca ejercer alguno de los derechos antes mencionados
            [Derechos ARCO], y;
          </li>
          <li style={{ listStyle: "none" }}>
            d) Cualquier otro elemento o documento que facilite la localización
            de los datos personales.
          </li>
        </ol>
        <p>
          En caso de tratarse de solicitudes presentadas en forma física,
          deberán estar debidamente firmadas por el titular o su representante
          legal, al calce del documento.
        </p>
        <p>
          Toda solicitud deberá estar dirigida al Departamento de Protección de
          Datos Personales de Tornebo.
        </p>
        <p>
          Asimismo, en caso de que el titular haya ejercitado alguno de los
          Derechos ARCO, no es un impedimento para que ejercite otro de sus
          Derechos ARCO que no haya ejercido anteriormente.
        </p>
        <p>
          7.3. Información de Tornebo para la recepción de las solicitudes.
          Todas las solicitudes de protección de Derechos ARCO deberán ser
          presentadas en un día hábil en un horario de 09:00 AM a 05:00 PM
          (salvo que se trate de solicitudes presentadas por correo
          electrónico), en el siguiente domicilio: Avenida Presidente Masaryk
          número 111, PH-1, Colonia Chapultepec Los Morales, 11570, Miguel
          Hidalgo, Ciudad de México.
        </p>
        <p>
          Alternativamente, el titular podrá enviar sus solicitudes por correo
          electrónico a la siguiente dirección electrónica:
          [stabienzo@gmail.com].
        </p>
        <p>
          En caso de que la solicitud sea presentada en formato físico a la
          dirección antes señalada, la notificación surtirá efectos el día que
          haya sido presentada. En caso de que sea enviada al correo electrónico
          antes señalado, la notificación surtirá efectos al día hábil siguiente
          en que Tornebo la haya recibido.
        </p>
        <p>
          7.4. Respuesta a las Solicitudes de Derechos ARCO. En caso de que el
          titular no haya satisfecho los requisitos señalados en el presente
          apartado, Tornebo podrá prevenir al titular en un plazo de 5 (cinco)
          días hábiles, contados a partir del día siguiente de la recepción de
          la solicitud, señalando la información o documentación que el titular
          haya omitido o haya presentado de forma incompleta, poco clara o
          imprecisa, para lo cual, el plazo de respuesta Tornebo, empezará a
          contar hasta el día en que el titular satisfaga completamente los
          requisitos aquí señalados.
        </p>
        <p>
          En caso de que el titular satisfaga los requisitos señalados en el
          presente apartado, Tornebo tendrá un plazo de 20 (veinte) días hábiles
          contados a partir del día siguiente en que surta efectos la
          notificación, para negar o admitir las solicitudes presentadas por los
          titulares, para lo cual, lo notificará al titular en el domicilio
          señalado en la solicitud. En caso de admitir la solicitud, Tornebo
          tendrá 15 (quince) días hábiles para cumplir con lo planteado por el
          titular, o en caso de negar total o parcialmente la solicitud, se lo
          hará saber a al titular, dejando a salvo los derechos que le concede
          el presente Aviso de Privacidad y la legislación aplicable.
        </p>
        <p>
          Se entenderá por día hábil cualquier día, excepto por (i) sábados y
          domingos; (ii) aquellos días que sean considerados como de descanso
          obligatorio por la Ley Federal del Trabajo de México; y (iii) aquellos
          días en que los bancos comerciales no realizan operaciones en la
          Ciudad de México.
        </p>
        <p>
          7.5. Negativa a las Solicitudes de Derechos ARCO. Tornebo podrá negar
          las solicitudes que se le presente parta la protección de Derechos
          ARCO, cuando se actualice alguno de los supuestos señalados en el
          artículo 34 de la Ley, para lo cual se lo notificará al titular, de
          conformidad con el procedimiento señalado en el presente apartado.
        </p>
        <p>
          Asimismo, Tornebo podrá negar las solicitudes de Derechos ARCO, cuando
          el titular no dé respuesta a las prevenciones realizadas por Tornebo
          en un plazo de 10 (diez) días hábiles, posteriores a la fecha en que
          surta efectos la notificación, o habiendo dado respuesta, no satisfaga
          los requisitos señalados la prevención realizada por Tornebo.
        </p>
        <h2>8. Transferencias de Datos Personales.</h2>
        <p>
          Tornebo compartirá sus datos personales, con las personas, empresas,
          instituciones o autoridades, distintas a Tornebo, para los siguientes
          fines:
        </p>
        <table style={{ border: "2px solid black", width: "100%" }}>
          <tr style={{ display: "flex" }}>
            <th
              style={{
                border: "1px solid black",
                flex: "1",
                textAlign: "center",
                fontSize: ".8em",
              }}
            >
              Datos personales que se transfieren
            </th>
            <th
              style={{
                border: "1px solid black",
                flex: "1",
                textAlign: "center",
                fontSize: ".8em",
              }}
            >
              Destinatario de los datos personales
            </th>
            <th
              style={{
                border: "1px solid black",
                flex: "1",
                textAlign: "center",
                fontSize: ".8em",
              }}
            >
              Finalidad
            </th>
          </tr>
          <tr style={{ display: "flex" }}>
            <td
              style={{
                border: "1px solid black",
                flex: "1",
                fontSize: ".8em",
                textAlign: "left",
              }}
            >
              Datos de cuentas bancarias.
            </td>
            <td
              style={{
                border: "1px solid black",
                flex: "1",
                fontSize: ".8em",
                textAlign: "left",
              }}
            >
              Instituciones de banca múltiple o auxiliares de crédito, o bien,
              empresas especializadas.
            </td>
            <td
              style={{
                border: "1px solid black",
                flex: "1",
                fontSize: ".8em",
                textAlign: "left",
              }}
            >
              Cobro de los servicios brindados por Tornebo.
            </td>
          </tr>
          <tr style={{ display: "flex" }}>
            <td
              style={{
                border: "1px solid black",
                flex: "1",
                fontSize: ".8em",
                textAlign: "left",
              }}
            >
              Datos de facturación del Cliente.
            </td>
            <td
              style={{
                border: "1px solid black",
                flex: "1",
                fontSize: ".8em",
                textAlign: "left",
              }}
            >
              Autoridades en materia fiscal.
            </td>
            <td
              style={{
                border: "1px solid black",
                flex: "1",
                fontSize: ".8em",
                textAlign: "left",
              }}
            >
              Realizar la facturación por los servicios de Tornebo.
            </td>
          </tr>
        </table>
        <p>
          En caso de que se deba realizar alguna otra transferencia, distinta a
          las señaladas en el presente apartado, Tornebo únicamente la podrá
          realizar con el previo consentimiento del titular, o su representante,
          en términos del segundo párrafo del artículo 36 de la Ley.
        </p>
        <h2>9. Revocación en el consentimiento.</h2>
        <p>
          9.1. Revocación. El consentimiento, ya sea expreso o tácito, podrá ser
          revocado por su titular, salvo en los casos en los que por alguna
          obligación legal o contractual, no sea posible atender a la solicitud
          de revocación de consentimiento, ya sea parcial o total.
        </p>
        <p>
          Para el procedimiento de revocación de consentimiento, se deberá
          atender al procedimiento señalado en el apartado 7 del Aviso de
          Privacidad, en el cual se deberá especificar si se trata de una
          revocación parcial o total, así como si se trata de datos con
          Finalidades Primarias, Finalidades Secundarias o ambas.
        </p>
        <p>
          9.2. Revocación Finalidades Primarias. Se debe tomar en cuenta, en el
          caso de revocación del consentimiento para tratamiento de datos con
          Finalidades Primarias, Tornebo ya no podrá prestar sus servicios, lo
          que lo exime de cualquier responsabilidad en la que pudiera incurrir,
          y ello no exime al Cliente de las obligaciones contraídas con Tornebo.
        </p>
        <p>
          9.3. Revocación Finalidades Secundarias. El titular podrá revocar
          parcial o totalmente, su consentimiento para el tratamiento de datos
          con Finalidades Secundarias. La negativa o revocación del
          consentimiento del titular de datos con Finalidades Secundarias, no
          podrá ser motivo para que Tornebo niegue totalmente la prestación de
          sus servicios, sino que Tornebo únicamente dejará de prestar los
          Servicios respecto de los que se requieran dichos datos, tomando en
          cuenta las excluyentes de responsabilidad señaladas en el numeral 9.2.
          anterior.
        </p>
        <h2>10. Limitación del Uso o Divulgación de los Datos.</h2>
        <p>
          De conformidad con la fracción III del artículo 16 de la Ley, el
          titular podrá limitar el uso y divulgación de sus datos, indicándolo
          así al inicio de la relación jurídica, o en su caso, mediante
          solicitud presentada a través del procedimiento señalado para tales
          efectos en el apartado 7 del Aviso de Privacidad.
        </p>
        <p>
          En adición a lo anterior, el titular podrá acudir, en términos de la
          legislación aplicable, al Registro Público para Evitar Publicidad
          (REPEP) de la Procuraduría Federal del Consumidor (PROFECO), con
          página web https://repep.profeco.gob.mx/, con el objetivo de obtener
          información respecto del procedimiento para evitar recibir publicidad
          o promociones de Tornebo.
        </p>
        <h2>11. Uso de cookies, beacons y tecnologías similares.</h2>
        <p>
          11.1. Cookies. El sitio web de Tornebo, https://uniomx.com/, utiliza
          cookies (dicho término se describe más adelante), para almacenar datos
          respecto de todas las personas que ingresen al sitio web de Tornebo,
          incluyendo la dirección IP.
        </p>
        <p>
          Los cookies son pequeños archivos enviados por Tornebo a quien accede
          a su página, mismos que se colocan en la computadora o dispositivo del
          usuario, para analizar la forma en que los usuarios utilizan la página
          web, así como consultar actividad previa en el navegador. El uso de
          los cookies por parte de Tornebo se realizará en los términos
          señalados en el presente apartado.
        </p>
        <p>
          Se le informa a todos los usuarios, que los cookies se recaban
          mediante un servicio de análisis web proporcionado por Google, Inc.
          denominado Google Analytics, así como sistemas de gestión de
          contenidos (“CMS” por sus siglas en inglés para Content Management
          System), que son los programas informáticos, a través de los cuales se
          crea y administra el contenido de una página web, y de igual forma,
          recaba la actividad de los usuarios en la página web.
        </p>
        <p>
          Para mayor información del uso de cookies a través de Google
          Analytics, se le sugiere al titular visitar la página web
          https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage.
        </p>
        <p>
          11.2. Configuración para Desactivar o Evitar Cookies. El usuario podrá
          desactivar o bloquear en cualquier momento, el uso de cookies, lo cual
          se deberá hacer de conformidad con las instrucciones señaladas por
          cada navegador o plataforma en la que el usuario acceda a la página
          web de Tornebo.
        </p>
        <p>
          11.3. Excluyente de Responsabilidad. En caso de que el usuario decida
          desactivar todos los cookies, en cualquiera de las plataformas
          digitales de Tornebo, se le informa que podría traer menoscabo en las
          funcionalidades, la calidad y la velocidad de los servicios prestados
          por Tornebo e inclusive se pudieran desactivar diversos servicios, sin
          responsabilidad para Tornebo.
        </p>
        <p>
          11.4. Redes Sociales. A través de la captura de cookies, el nombre
          proporcionado por el titular o su correo electrónico, Tornebo podrá
          acceder a diversas redes sociales, consideras dentro de la categoría
          de Datos de Fuentes de Acceso Público con la finalidad de ingresar a
          foros en materia de seguridad privada familiar y enviar información y
          contenido de los servicios prestados por Tornebo; el titular de los
          datos personales, tendrá en todo momento, de conformidad con el Aviso
          de Privacidad, el derecho de eliminar, bloquear o abandonar cualquier
          conexión con Tornebo en las redes sociales, y en su caso, ejercer sus
          derechos aquí señalados.
        </p>
        <h2>Cambios en el Aviso de Privacidad.</h2>
        <p>
          El presente Aviso de Privacidad puede sufrir modificaciones, cambios o
          actualizaciones, derivado de nuevas disposiciones legales, prácticas
          comerciales, las necesidades de Tornebo en relación a la oferta de los
          productos o servicios, modificaciones en las políticas de privacidad
          de Tornebo o cualquiera otra razón, para lo cual se le notificará
          mediante nuestra página web https://uniomx.com/ en el apartado
          relativo a los Avisos de Privacidad.
        </p>
        <h2>13. Derechos de los Titulares.</h2>
        <p>
          Se le recuerda a todas aquellas personas a quienes se encuentre
          dirigido el presente Aviso de Privacidad, que en caso de que
          consideren que Tornebo ha vulnerado su derecho a la protección de
          datos personales, y posterior a haber hecho su solicitud de protección
          de derechos señalada en el presente Aviso de Privacidad, les asiste el
          derecho de acudir, en términos de la legislación aplicable, al
          Instituto Nacional de Transparencia, Acceso a la Información y
          Protección de Datos Personales “INAI”, cuya página web es
          www.inai.org.mx/.
        </p>
        <br />
        <p style={{ textAlign: "right" }}>
          Última actualización: 12 de junio del 2019
        </p>
      </div>
    </div>
  )
}
